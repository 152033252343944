<template>
  <div class="about">
    <el-card>
      <el-form
        :model="query"
        label-width="150px"
        v-loading="loading"
        :inline="true"
        label-position="left"
      >
        <el-form-item
          v-if="this.fatherItem.id === 0"
          label="全部展馆"
        ></el-form-item>
        <el-form-item
          v-else
          :label="`${this.fatherItem.name} 展位`"
        ></el-form-item>
        <el-form-item>
          <el-input
            v-model="query.keyword"
            maxlength="50"
            placeholder="名称"
            clearable
            @keyup.enter.native="onSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >搜索</el-button
          >
          <slot name="tip"></slot>
        </el-form-item>
      </el-form>
      <el-button
        v-if="this.fatherItem.id === 0"
        type="primary"
        size="small"
        icon="el-icon-plus"
        plain
        style="margin-bottom: 10px"
        @click="handleUpdate(0, query.parentId)"
        >新增展馆
      </el-button>
      <el-button
        v-else
        type="primary"
        size="small"
        icon="el-icon-plus"
        plain
        style="margin-bottom: 10px"
        @click="handleUpdate(0, query.parentId)"
        >新增展位
      </el-button>
      <el-table
        :data="tableData"
        v-loading="loading"
        @sort-change="onOrder"
        ref="multipleTable"
        border
        fit
      >
        <el-table-column prop="sortNum" label="序号" width="60" align="center">
        </el-table-column>
        <el-table-column prop="name" label="名称" width="150">
        </el-table-column>
        <el-table-column prop="postition" label="位置" width="150">
        </el-table-column>
        <el-table-column label="编辑时间" width="160">
          <template slot-scope="scope">
            {{ moment(scope.row.editTime).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="130"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-show="!scope.row.isPassed"
                @click="handleUpdate(scope.row.id, scope.row.parentId)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="search"
        @current-change="search"
        :current-page.sync="query.pageIndex"
        :page-sizes="[150, 200]"
        :page-size.sync="query.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <Edit ref="edit"></Edit>
  </div>
</template>
<script>
import { del, adminSearch } from "@/api/booth/search.js";
import Edit from "./Edit.vue";

export default {
  name: "Child",
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 150,
        orderFd: null,
        orderBy: null,
        parentId: 0,
      },
      loading: false,
      tableData: [],
      total: 0,
      fatherItem: { id: 0 },
    };
  },
  components: {
    Edit,
  },
  methods: {
    /** 搜索按钮操作 */
    onSearch(queryParams) {
      this.query.pageIndex = 1;
      Object.assign(this.query, queryParams);
      this.search();
    },
    handleUpdate(id, parentId) {
      this.$refs.edit.show(id, parentId);
    },
    handleDelete(row) {
      this.$confirm(`是否要删除[${row.name}]?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        del(row.id).then(() => {
          this.loading = false;

          this.search();
          this.msgSuccess();
        });
      });
    },
    onOrder(value) {
      this.query.orderFd = value.prop;
      this.query.orderBy = value.order;
      this.search();
    },
    search(item) {
      this.loading = true;
      if (item != null) {
        this.query.parentId = item.id;
        this.fatherItem = item;
      }
      adminSearch(this.query).then((res) => {
        this.tableData = res.datas;
        this.total = res.totalRecords;
        this.loading = false;
      });

      this.$emit("fatherSearch");
    },
  },
  created() {
    this.search();
  },
};
</script>
<style scoped>
.box {
  position: fixed;
  right: 100px;
  bottom: 20px;
  z-index: 100;
}
</style>