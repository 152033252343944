import request from '@/utils/request'

export function getBooth(id) {
    return request({
        url: `/booth/${id}`,
        method: 'get'
    })
}

export function listBooth(parentId) {
    return request({
        url: `/booth/list/${parentId}`,
        method: 'get'
    })
}

export function updateBooth(id, data) {
    if (id) {
        return request({
            url: `/booth/${id}`,
            method: 'put',
            data: data
        })
    }

    return request({
        url: `/booth`,
        method: 'post',
        data: data
    })
}