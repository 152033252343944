<template>
  <div>
    <el-dialog
      title="编辑信息"
      :visible.sync="visible"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="150px"
        v-loading="loading"
      >
        <el-row>
          <el-col :span="24" v-show="false">
            <el-form-item label="所属展厅" prop="parentId">
              <el-input
                v-model="form.parentId"
                placeholder="请输入所属展厅ID"
                maxlength="2"
                show-word-limit
                disabled
              />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入名称"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="位置" prop="postition">
              <el-input
                v-model="form.postition"
                placeholder="请输入位置"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Json" prop="jsonConfig">
              <el-input
                v-model="form.jsonConfig"
                placeholder="请输入坐标大小"
                maxlength="500"
                show-word-limit
                @keyup.enter.native="submit"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getBooth, updateBooth } from "@/api/booth/booth.js";

export default {
  name: "Edit",
  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      // 表单校验
      rules: {},
      states: false,
    };
  },
  methods: {
    get(id, parentId) {
      this.visible = true;
      this.loading = true;
      getBooth(id).then((res) => {
        this.form = res;
        if (this.form.parentId != parentId) this.form.parentId = parentId;
        this.form.parentId = parentId;
        this.loading = false;
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          updateBooth(this.form.id, this.form)
            .then(() => {
              this.msgSuccess();
              this.loading = false;
              this.visible = false;

              this.$parent.search();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    show(id, parentId) {
      this.get(id, parentId);
    },
  },
};
</script>

<style></style>